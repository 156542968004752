



































































import { noop } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Favorite from '@/api/models/favorite';
import DeleteConfirmFormview from '@/components/utils/DeleteConfirmFormview.vue';

@Component({
  components: {
    DeleteConfirmFormview,
  }
})
export default class FavoritesListFormActions extends Vue {
  /** 
   * お気に入りリスト
   */
  @Prop({ required: true })
  favorites!: Favorite[];

  /** 
   * 編集お気に入り
   */
  @Prop()
  editFavorite?: Favorite;

  /** 
   * 選択お気に入り
   */
  @Prop()
  selectedFavorite?: Favorite;

  /** 
   * 追加ボタン
   */
  @Prop({ default: () => noop })
  create!: () => void;

  /** 
   * 編集ボタン
   */
  @Prop({ default: () => noop })
  edit!: () => void;

  /** 
   * キャンセルボタン
   */
  @Prop({ default: () => noop })
  cancel!: () => void;

  /** 
   * 保存ボタン
   */
  @Prop({ default: () => noop })
  submit!: () => void;

  /** 
   * 削除ボタン
   */
  @Prop({ default: () => noop })
  remove!: () => Promise<void>;

  /** 
   * 削除ボタン
   */
  @Prop({ default: () => noop })
  active!: () => Promise<void>;

  dialog = false;
  limit = 20;

  onRemove() {
    this.dialog = false;
    this.remove();
  }

  get disabledSubmit() {
    return this.active; // 通信中
  }

  get disabledCancel() {
    return this.active; // 通信中
  }

  get limited() {
    return this.favorites.length >= this.limit;
  }

  get disabledCreate() {
    return this.limited // 上限で作成不可
        || this.active; // または通信中
  }

  get disabledEdit() {
    return !this.selectedFavorite // 未選択
        || this.active; // または通信中
  }

  get disabledRemove() {
    return !this.selectedFavorite // 未選択
        || this.active; // または通信中
  }

  get selectedFavoriteTitle() {
    return this.selectedFavorite ? this.selectedFavorite.title : '';
  }

}
