




























import { noop } from 'vue-class-component/lib/util';
import { Component, Prop, Vue } from 'vue-property-decorator';

import CoreSlideSwitch from '@/components/utils/CoreSlideSwitch.vue';

@Component({
  components: {
    CoreSlideSwitch,
  }
})
export default class SearchTextInput extends Vue {
  /**
   * v-model用
   */
  @Prop()
  value?: string;

  /**
   * 検索する関数
   */
  @Prop({ default: noop})
  search!: () => void;

  onClear() {
    this.$emit('input', undefined);
  }

}
