






























import { noop } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Favorite from '@/api/models/favorite';

@Component
export default class FavoritesListItem extends Vue {
  /**
   * 表示用お気に入り。
   */
  @Prop({ default: () => new Favorite() })
  show!: Favorite;

  /**
   * お気に入りモデル。v-modelとして利用。編集用
   */
  @Prop({ required: true })
  value!: Favorite;

  /**
   * 編集モード
   */
  @Prop({ default: false })
  editable!: boolean;

  /**
   * 選択中
   */
  @Prop({ default: false })
  selected!: boolean;

  /**
   * エンターキーイベント
   */
  @Prop({ default: () => noop })
  enter!: () => void;

  /**
   * エスケープキーイベント
   */
  @Prop({ default: () => noop })
  esc!: () => void;

  /**
   * クリックイベント
   */
  @Prop({ default: () => noop })
  click!: () => void;

  /**
   * クリックイベント
   */
  @Prop({ default: false })
  disabledClick!: boolean;

  /**
   * エラー
   */
  @Prop({ default: () => ({})})  
  errors!: Partial<Record<keyof Favorite, string>>;

  onClick() {
    if (this.disabledClick) return;
    this.click();
  }

}
