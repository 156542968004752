





































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

import LeftSideMenuTab from '@/components/LeftSideMenuTab.vue';

import { LeftSideMenuTabItem } from './LeftSideMenu';

@Component({
  components: {
    LeftSideMenuTab,
  }
})
export default class LeftSideMenu extends Vue {

  /**
   * タブ情報配列
   */
  @Prop({ required: true })
  tabs!: LeftSideMenuTabItem[];

  /**
   * メニューを開き状態(open.syncとして利用)
   */
  @PropSync('open')
  opened!: boolean;

  /**
   * 選択中タブ(tab.syncとして利用)
   */
  @PropSync('tab')
  selectTab!: string | null;

  /**
   * 閉じた時タブを維持する
   * ※プロップ名のみで利用可
   */
  @Prop({ type: Boolean })
  keepTab?: boolean;

  onOpen(openedTab: LeftSideMenuTabItem) {
    this.opened = true;
    this.selectTab = openedTab.name;
  }

  onClose() {
    this.opened = false;
    if (!this.keepTab) {
      this.selectTab = null;
    }
  }
}
