







































import { Component, Prop, Vue } from 'vue-property-decorator';

import CaseStudiesSearchForm from '@/api/models/case_studies_search_form';
import Me from '@/api/models/me';
import Tag from '@/api/models/tag';
import SearchTagSection from '@/components/SearchTagSection.vue';
import promiseNoop from '@/utils/promise_noop';

import SearchTextInput from './SearchTextInput.vue';
import UnpublishedCaseStudyButton from './UnpublishedCaseStudyButton.vue';

@Component({
  components: {
    SearchTagSection,
    SearchTextInput,
    UnpublishedCaseStudyButton,
  }
})
export default class CaseStudiesSearchMenu extends Vue {
  /**
   * ログインユーザー
   */
  @Prop({ required: true })
  me!: Me;

  /**
   * タグ配列
   */
  @Prop({ required: true })
  tags!: Tag[];

  /**
   * 事例検索フォーム
   */
  @Prop({ required: true })
  value!: CaseStudiesSearchForm;

  /**
   * 検索メソッド
   */
  @Prop({ default: () => promiseNoop })
  search!: () => Promise<void>;

  async onSearch() {
    await this.search();
  }

  onInputUnpublishedCaseStudyFlag(value: boolean) {
    this.value.unpublishedCaseStudyFlag = value;
    this.onSearch();
  }

  onInputTagDtsUuidArray(value: string[]) {
    this.value.tagDtsUuidArray = value;
    this.onSearch();
  }

  onClear() {
    this.value.clear();
    this.onSearch();
  }
  
  get showUnpublishedCaseStudyButton() {
    return this.me.canEditCaseStudies; // ユーザーが事例編集権限を持つときのみ
  }
}
