







import { Component, Prop, Vue } from 'vue-property-decorator';

import TagIcon from '@/components/utils/TagIcon.vue';

@Component({
  components: {
    TagIcon,
  }
})
export default class HoverableTagIcon extends Vue { 
  /**
   * ホバーした時のタイトル
   */
  @Prop({ default: '' })
  title?: string;
}
