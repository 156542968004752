


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CoreSlideSwitch<T = boolean> extends Vue {
  /**
   * v-model用
   */
  @Prop({ required: true })
  value?: T;

  /**
   * チェックしている場合、inputが発行する値
   */
  @Prop({ default: true })
  whenTrue!: T;

  /**
   * チェックしていない場合、inputが発行する値
   */
  @Prop({ default: false })
  whenFalse!: T;

  /**
   * 無効化
   */
  @Prop({ default: false })
  disabled!: boolean;

  get checked() {
    return this.value === this.whenTrue;
  }

  set checked(checked: boolean) {

    /**
     * v-model用
     */
    this.$emit('input', checked ? this.whenTrue : this.whenFalse);
  }

  onToggle() {
    if (!this.disabled)
      this.checked = !this.checked;
  }
}
