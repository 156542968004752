

















































































import { noop } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

import CaseStudy from '@/api/models/case_study';
import Tag from '@/api/models/tag';
import HoverableTagIcon from '@/components/utils/HoverableTagIcon.vue';
import promiseNoop from '@/utils/promise_noop';

@Component({
  components: {
    HoverableTagIcon,
  }
})
export default class CaseStudiesTableRow extends Vue {

  /**
   * 事例
   */
  @Prop({ required: true })
  caseStudy!: CaseStudy;

  /**
   * 事例を開く
   */
  @Prop({ default: () => noop })
  open!: (caseStudy: CaseStudy) => void;

  /**
   * タグ配列
   */
  @Prop({ required: true })
  tags!: Required<Tag>[];

  /**
   * お気に入りモード
   */
  @Prop({ default: false })
  favorite!: boolean;

  /**
   * 事例を登録解除
   */
  @Prop({ default: () => promiseNoop })
  unregister!: (caseStudy: CaseStudy) => Promise<void>;

  active = false;

  async onUnregister() {
    if (this.active) return;
    this.active = true;
    try {
      await this.unregister(this.caseStudy);
    } finally {
      this.active = false;
    }
  }

  get disabledUnregister() {
    return this.active;
  }

}
