























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import CaseStudy from '@/api/models/case_study';
import Tag from '@/api/models/tag';
import CaseStudiesTableRow from '@/components/CaseStudiesTableRow.vue';
import CaseStudiesTableRowUnregisted from '@/components/CaseStudiesTableRowUnregisted.vue';
import CoreTable from '@/components/utils/CoreTable.vue';
import promiseNoop from '@/utils/promise_noop';

@Component({
  components: {
    CoreTable,
    CaseStudiesTableRow,
    CaseStudiesTableRowUnregisted,
  }
})
export default class CaseStudiesTable extends Vue {
  /**
   * v-model用(将来チェックボックスを追加したとき用で今は使っていない)
   */
  @Prop({ required: false })
  value!: CaseStudy[];

  /**
   * 表示用事例一覧
   */
  @Prop({ required: true })
  caseStudies!: CaseStudy[];

  /**
   * タグ配列
   */
  @Prop({ required: true })
  tags!: Required<Tag>[];

  /**
   * お気に入りモード
   */
  @Prop({ default: false })
  favorite!: boolean;

  /**
   * 事例を開く
   */
  @Prop({ default: true })
  open!: (caseStudy: CaseStudy) => void;

  /**
   * 事例を登録解除
   */
  @Prop({ default: () => promiseNoop })
  unregister!: (caseStudy: CaseStudy) => Promise<void>;

  /**
   * 登録解除を元に戻す
   */
  @Prop({ default: () => promiseNoop })
  undo!: (caseStudy: CaseStudy) => Promise<void>;

  unregisteredByUuid: {[key: string]: boolean} = {};

  async onUnregister(caseStudy: Required<CaseStudy>) {
    await this.unregister(caseStudy);
    this.$set(this.unregisteredByUuid, caseStudy.uuid, true);
  }

  async onUndo(caseStudy: Required<CaseStudy>) {
    await this.undo(caseStudy);
    this.$set(this.unregisteredByUuid, caseStudy.uuid, false);
  }

  @Watch('caseStudies')
  clearUnregisteredByUuid() {
    this.unregisteredByUuid = {};
  }
}
