





























































































import { Pagination } from '@uniquevision/libraries.beluga_ui/src/components/UvPageControls';
import { debounce, find } from 'lodash';
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { RawLocation, Route } from 'vue-router';

import { emptyDataList } from '@/api';
import CaseStudiesFavoritesForm from '@/api/models/case_studies_favorites_form';
import CaseStudiesSearchForm from '@/api/models/case_studies_search_form';
import CaseStudy from '@/api/models/case_study';
import Favorite from '@/api/models/favorite';
import Me from '@/api/models/me';
import Tag from '@/api/models/tag';
import CaseStudiesSearchMenu from '@/components/CaseStudiesSearchMenu.vue';
import CaseStudiesTable from '@/components/CaseStudiesTable.vue';
import FavoritesListForm from '@/components/FavoritesListForm.vue';
import KbnSelect from '@/components/KbnSelect.vue';
import LeftSideMenu from '@/components/LeftSideMenu.vue';
import CoreFooter from '@/components/utils/CoreFooter.vue';
import CoreTitle from '@/components/utils/CoreTitle.vue';
import { CaseStudiesFavoriteState } from '@/store/case_studies_favorite_state';
import { CaseStudiesSearchState } from '@/store/case_studies_search_state';
import { CaseStudiesTabState, CaseStudiesTabType } from '@/store/case_studies_tab_state';
import { GlobalLoadingScreenModule } from '@/store/global_loading_screen';
import { CaseStudySortKbn, KbnConst } from '@/utils/kbn';
import { LS_CASE_STUDIES_LEFT_SIDE_MENU_OPEN } from '@/utils/local_storage';
import promiseNoop from '@/utils/promise_noop';

import { LeftSideMenuTabItem } from './LeftSideMenu';

interface CaseStudiesTabItem extends LeftSideMenuTabItem {
  name: CaseStudiesTabType;
}

@Component({
  components: {
    CoreFooter,
    CaseStudiesTable,
    LeftSideMenu,
    CaseStudiesSearchMenu,
    FavoritesListForm,
    CoreTitle,
    KbnSelect
  }
})
export default class CaseStudiesSearchTemplate extends Vue {

  @Inject()
  globalLoadingScreenModule!: GlobalLoadingScreenModule;

  @Inject()
  caseStudiesSearchState!: CaseStudiesSearchState;

  @Inject()
  caseStudiesFavoriteState!: CaseStudiesFavoriteState;

  @Inject()
  caseStudiesTabState!: CaseStudiesTabState;

  /**
   * タグ配列
   */
  @Prop({ required: true })
  tags!: Tag[];

  /**
   * getMe APIコールバック
   */
  @Prop({ required: true })
  getMe!: () => Promise<Me>;

  /**
   * getCaseStudies APIコールバック
   */
  @Prop({ required: true })
  getCaseStudies!: (caseStudiesSearchForm: CaseStudiesSearchForm) => Promise<{items: CaseStudy[]; totalCount: number }>;
  
  /**
   * getFavorites APIコールバック
   */
  @Prop({ required: true })
  getFavorites!: () => Promise<{ items: Favorite[]; totalCount: number }>;

  /**
   * postFavorite APIコールバック
   */
  @Prop({ default: () => promiseNoop })
  postFavorite!: (favorite: Favorite) => Promise<string>;

  /**
   * putFavorite APIコールバック
   */
  @Prop({ default: () => promiseNoop })
  putFavorite!: (favorite: Favorite) => Promise<void>;

  /**
   * deleteFavorite APIコールバック
   */
  @Prop({ default: () => promiseNoop })
  deleteFavorite!: (favorite: Favorite) => Promise<void>;

  /**
   * getFavoriteCaseStudies APIコールバック
   */
  @Prop({ required: true })
  getFavoriteCaseStudies!: (paseStudiesFavoritesForm: CaseStudiesFavoritesForm) => Promise<{ items: CaseStudy[]; totalCount: number }>;

  /**
   * putFavoriteCaseStudy APIコールバック
   */
  @Prop({ default: () => promiseNoop })
  putFavoriteCaseStudy!: (favorite: Favorite, caseStudy: CaseStudy) => Promise<void>;

  /**
   * deleteFavoriteCaseStudy APIコールバック
   */
  @Prop({ default: () => promiseNoop })
  deleteFavoriteCaseStudy!: (favorite: Favorite, caseStudy: CaseStudy) => Promise<void>;

  /**
   * router.push APIコールバック
   */
  @Prop({ required: true })
  routerPush!: (location: RawLocation) => Promise<Route>;

  me = new Me();
  caseStudies: CaseStudy[] = [];
  totalCount = 0;
  favorites: Favorite[] = [];
  pagination: Pagination | null = null;
  active = false;
  menuOpen = true;
  tabs: CaseStudiesTabItem[] = [
    { name: 'search', icon: 'search' },
    { name: 'favorites', icon: 'favourite_list' },
  ];

  async created() {
    this.initializeMenuOpen();
    await Promise.all([
      this.onGetMe(),
      this.onGetFavorites(),
    ]);
    // お気に入り取得後に検索したいので、このタイミングで実行しなければならない
    await this.searchOnFormSaved();
  }

  initializeMenuOpen() {
    const boolStr = sessionStorage.getItem(LS_CASE_STUDIES_LEFT_SIDE_MENU_OPEN);
    if ( boolStr ) this.menuOpen = boolStr === 'true';
  }
  
  @Watch('menuOpen')
  saveMenuOpen(menuOpen: boolean) {
    sessionStorage.setItem(LS_CASE_STUDIES_LEFT_SIDE_MENU_OPEN, menuOpen.toString());
  }

  // データ取得
  async onGetMe() {
    this.me = await this.globalLoadingScreenModule.track(this.getMe());
  }

  async onGetFavorites() {
    const { items } = await this.globalLoadingScreenModule.track(this.getFavorites());
    this.favorites = items;
  }
  
  get searchForm() {
    return this.caseStudiesSearchState.form;
  }
  
  get favoriteForm() {
    return this.caseStudiesFavoriteState.form;
  }

  get tab() {
    return this.caseStudiesTabState.tab;
  }
  set tab(value: CaseStudiesTabType) {
    this.caseStudiesTabState.save(value);
  }

  get currentForm() {
    switch (this.tab) {
      case 'search': return this.searchForm;
      case 'favorites': return this.favoriteForm;
    }
  }

  get currentFavorite() {
    return find(this.favorites, ['uuid', this.favoriteForm.favoriteUuid]);
  }

  get caseStudySortKbn() {
    return KbnConst.CASE_STUDY_SORT_KBN;
  }

  // 表示制御
  get showPageControls() {
    return this.totalCount; // 検索結果があるときに表示する
  }

  get showPagination() {
    return this.pagination
     && this.totalCount; // 検索結果があるときに表示する
  }

  get showNewButton() {
    return this.me.canEditCaseStudies; // 事例編集権限を持つときのみ表示
  }

  get title() {
    if (this.tab === 'favorites' && this.currentFavorite) {
      return this.currentFavorite.title;
    }
    return undefined;
  }

  // 検索実行系
  onSearchCaseStudiesMenu() {
    this.searchForm.resetPage();
    this.caseStudiesSearchState.save(this.searchForm);
  }

  onSearchFavoritesMenu() {
    this.favoriteForm.resetPage();
    this.caseStudiesFavoriteState.save(this.favoriteForm);
  }

  onSeek(page: number) {
    switch (this.tab) {
      case 'search': {
        this.searchForm.page = page;
        this.caseStudiesSearchState.save(this.searchForm);
        return;
      }
      case 'favorites': {
        this.favoriteForm.page = page;
        this.caseStudiesFavoriteState.save(this.favoriteForm);
        return;
      }
    }
  }

  onCaseStudySortKbn(value: CaseStudySortKbn) {
    this.searchForm.resetPage();
    this.searchForm.sortKbn = value;
    this.caseStudiesSearchState.save(this.searchForm);
  }

  @Watch('caseStudiesSearchState.version')
  @Watch('caseStudiesFavoriteState.version')
  @Watch('caseStudiesTabState.version')
  searchOnFormSavedDebounced = debounce(this.searchOnFormSaved, 1, { maxWait: 1 });
  // debounceはプログラムによる連続更新に備えて設置している。そのため待ち時間は1ms

  async searchOnFormSaved() {
    if (this.active) return; // ２重実行防止
    try {
      this.active = true;
      const res = await this.callSearchApi();
      this.caseStudies = res.items;
      this.totalCount = res.totalCount;
    } finally {
      this.active = false;
    }
  }

  async callSearchApi() {
    switch (this.tab) {
      case 'search': return await this.globalLoadingScreenModule.track(this.getCaseStudies(this.searchForm));
      case 'favorites': return await this.globalLoadingScreenModule.track(this.onGetFavoriteCaseStudies(this.favoriteForm));
    }
  }

  async onGetFavoriteCaseStudies(favoriteForm: CaseStudiesFavoritesForm) {
    favoriteForm.fixFavoriteUuid(this.favorites);
    if (favoriteForm.favoriteUuid) return await this.getFavoriteCaseStudies(favoriteForm);
    else return emptyDataList;
  }

  // お気に入り登録/削除
  async favoriteSubmit(favorite: Favorite) {
    if (favorite.uuid) {
      await this.globalLoadingScreenModule.track(this.putFavorite(favorite));
    } else {
      await this.globalLoadingScreenModule.track(this.postFavorite(favorite));
    }
    await this.onGetFavorites();
  }

  async favoriteRemove(favorite: Favorite) {
    await this.globalLoadingScreenModule.track(this.deleteFavorite(favorite));
    await this.onGetFavorites();
  }

  async onUnregister(caseStudy: CaseStudy) {
    if (this.currentFavorite) await this.deleteFavoriteCaseStudy(this.currentFavorite, caseStudy);
  }

  async onUndo(caseStudy: CaseStudy) {
    if (this.currentFavorite) await this.putFavoriteCaseStudy(this.currentFavorite, caseStudy);
  }

  // 画面遷移系
  onNew() {
    this.routerPush({ name: 'CaseStudyNew' });
  }

  onCaseStudyOpen(caseStudy: CaseStudy) {
    if (caseStudy.uuid) {
      this.routerPush({ name: 'CaseStudyShow', params: { 'case_study_uuid': caseStudy.uuid, 'from_list_flag': 'true' } });
    }
  }
}
