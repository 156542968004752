




















import { noop } from 'vue-class-component/lib/util';
import { Component, Prop, Vue } from 'vue-property-decorator';

import Tag from '@/api/models/tag';
import TagIcon from '@/components/utils/TagIcon.vue';

@Component({
  components: {
    TagIcon,
  }
})
export default class TagTitleSolid extends Vue {

  /**
   * タグ
   */
  @Prop({ required: true})
  tag!: Tag;

  @Prop({ required: true })
  opened!: boolean;

  @Prop({ default: () => noop })
  toggle!: () => void;

  get rotate() {
    return this.opened ? 180 : 0;
  }
}
