























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class UnpublishedCaseStudyButton extends Vue {
  /**
   * trueの時は「非公開の事例も表示」、falseの時は「公開の事例のみ表示」
   * v-modelとして利用
   */
  @Prop({ required: true })
  value!: boolean;

  onToggle() {
    /**
     * v-model用
     */
    this.$emit('input', !this.value);
  }

}
