



















import { Component, Prop, Vue } from 'vue-property-decorator';

import CaseStudy from '@/api/models/case_study';
import promiseNoop from '@/utils/promise_noop';

@Component
export default class CaseStudiesTableRowUnregisted extends Vue {
  /**
   * 事例
   */
  @Prop({ required: true })
  caseStudy!: CaseStudy;
  
  /**
   * 削除した事例をもとに戻す。
   */
  @Prop({ default: () => promiseNoop })
  undo!: (caseStudy: CaseStudy) => Promise<void>;

  active = false;

  async onUndo() {
    if (this.active) return;
    this.active = true;
    try {
      await this.undo(this.caseStudy);
    } finally {
      this.active = false;
    }
  }

  get disabledUndo() {
    return this.active;
  }

}
