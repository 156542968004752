



















import { Component, Inject, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

import { Api } from '@/api';
import CaseStudiesSearchTemplate  from '@/components/CaseStudiesSearchTemplate.vue';
import { GlobalMe } from '@/store/global_me';
import { GlobalTag } from '@/store/global_tag';

@Component({
  components: {
    CaseStudiesSearchTemplate,
  }
})
export default class CaseStudiesIndex extends Vue {

  @Inject()
  api!: Api;

  @Inject()
  globalTagModule!: GlobalTag;

  @Inject()
  globalMeModule!: GlobalMe;

  routerPush(location: RawLocation) {
    return this.$router.push(location);
  }

}
