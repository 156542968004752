









































import { classToPlain, plainToClass } from 'class-transformer';
import { find, isEmpty } from 'lodash';
import { Component, Mixins, Prop } from 'vue-property-decorator';

import CaseStudiesFavoritesForm from '@/api/models/case_studies_favorites_form';
import Favorite from '@/api/models/favorite';
import FavoritesListFormActions from '@/components/FavoritesListFormActions.vue';
import FavoritesListItem from '@/components/FavoritesListItem.vue';
import Validatable from '@/mixins/validatable';
import promiseNoop from '@/utils/promise_noop';

@Component({
  components: {
    FavoritesListFormActions,
    FavoritesListItem,
  }
})
export default class FavoritesListForm extends Mixins(Validatable) {
  /** 
   * お気に入り一覧
   */
  @Prop({ required: true })
  favorites!: Favorite[];

  /**
   * 検索フォーム（v-model用）
   */
  @Prop({ required: true })
  value!: CaseStudiesFavoritesForm;

  /** 
   * 事例検索
   */
  @Prop({ default: () => promiseNoop })
  search!: () => Promise<void>;

  /** 
   * お気に入り登録/編集
   */
  @Prop({ default: () => promiseNoop })
  submit!: (favorite: Favorite) => Promise<void>;

  /** 
   * お気に入り削除
   */
  @Prop({ default: () => promiseNoop })
  remove!: (favorite: Favorite) => Promise<void>;

  editFavorite: Favorite | null = null;
  active = false;
  errors: Partial<Record<keyof Favorite, string>> = {};

  isSelected(favorite: Favorite) {
    return favorite.uuid === this.value.favoriteUuid;
  }

  async onSearch(favorite: Favorite) {
    if (this.editFavorite) return;
    if (this.active) return;
    try {
      this.active = true;
      this.$set(this.value, 'favoriteUuid', favorite.uuid);
      await this.search();
    } finally {
      this.active = false;
    }
  }

  async onSubmit() {
    if (this.active) return;
    try {
      this.active = true;
      if (!this.editFavorite) return;

      this.errors = await this.validate(this.editFavorite);
      if (!isEmpty(this.errors)) return;

      await this.submit(this.editFavorite);
      this.editFavorite = null;

    } finally {
      this.active = false;
    }
  }
  onCancel() {
    this.editFavorite = null;
  }

  onCreate() {
    this.errors = {};
    this.editFavorite = new Favorite();
  }

  onEdit() {
    this.errors = {};
    this.editFavorite = plainToClass(Favorite, classToPlain(this.selectedFavorite));
  }

  async onRemove() {
    if (this.active) return;
    try {
      this.active = true;
      if (this.selectedFavorite) {
        await this.remove(this.selectedFavorite);
        await this.search();
      }
    } finally {
      this.active = false;
    }
  }

  get selectedFavorite() {
    return find(this.favorites, ['uuid', this.value.favoriteUuid]);
  }

  get disabledItemClick() {
    return this.active
    || !!this.editFavorite; // 編集中は検索不可
  }

}
