















import { Component, Prop, Vue } from 'vue-property-decorator';

import { LeftSideMenuTabItem } from './LeftSideMenu';

@Component
export default class LeftSideMenuTab extends Vue {
  @Prop({ required: true })
  tab!: LeftSideMenuTabItem;

  @Prop({ required: true })
  selected!: boolean;

  @Prop({ required: true })
  openedMenu!: boolean;
}
